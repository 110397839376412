import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import {
  refetchSizes,
  UPDATE_PRODUCT_SIZES,
  UPDATE_SIZE_ATTRIBUTION,
  UPDATE_SIZES_AND_ATTRIBUTION,
  updateSizeAttributionSuccess,
} from 'src/actions/grid/product/sizes'
import { errorAndNotification } from '../error'
import { showNotification } from 'src/actions/notification'
import { OPTION_LIBRARY } from 'src/types/index'
import { updateProductSizesSaga } from './updateSizes'
import { injectorService } from 'src/service/injector/injectorService'
import { reloadProductSaga } from './reload'
import { getSelectedHierarchySlug } from 'src/selectors/modals/sizeModal'
import {
  definedSizeAttributions,
  mapApiSizeAttributionToSizeAttribution,
} from 'src/service/mappers/sizeAttribution'
import {
  setSizeModalStatusPosting,
  setSizeModalStatusReady,
} from 'src/actions/grid/modal/sizeModal'
import { SVPAction } from 'actions/svp'
import { APIError } from 'types/Api'

const sizeAttributeAPI = (productSlug: string): string => `products/${productSlug}/sizeAttribution`

export function* updateSizeAttributionSaga(
  action: SVPAction<typeof UPDATE_SIZE_ATTRIBUTION>,
  hideNotifications?: boolean,
) {
  try {
    const { productSlug, supplierCostCurrency, prices } = action.payload
    yield put(setSizeModalStatusPosting())
    yield put(
      updateSizeAttributionSuccess(
        mapApiSizeAttributionToSizeAttribution(prices),
        supplierCostCurrency,
      ),
    )

    yield call(injectorService.patch, sizeAttributeAPI(productSlug), {
      sizeAttribution: definedSizeAttributions(prices),
      supplierCostCurrency,
    })

    if (!hideNotifications) {
      yield call(reloadProductSaga, productSlug, OPTION_LIBRARY)
      yield put(setSizeModalStatusReady())
      yield put(
        showNotification({
          type: 'success',
          message: 'Prices successfully updated',
        }),
      )
    }
  } catch (error) {
    yield put(setSizeModalStatusReady())
    if (!hideNotifications) {
      yield call(
        errorAndNotification,
        error as APIError,
        "Prices couldn't be saved. Please try again later.",
      )
    } else {
      //something went wrong show only last notification
      throw error
    }
  }
}

export function* updateSizesAndAttributionsSaga(
  action: SVPAction<typeof UPDATE_SIZES_AND_ATTRIBUTION>,
) {
  const { productSlug, developmentId, sizes, prices, supplierCostCurrency } = action.payload
  const hierarchySlug: string = yield select(getSelectedHierarchySlug)
  try {
    yield call(
      updateSizeAttributionSaga,
      {
        type: UPDATE_SIZE_ATTRIBUTION,
        payload: { productSlug, prices, supplierCostCurrency },
      },
      true,
    )

    yield call(
      updateProductSizesSaga,
      {
        type: UPDATE_PRODUCT_SIZES,
        payload: { sizes, productSlug, developmentId },
      },
      true,
    )

    yield put(refetchSizes(productSlug, hierarchySlug))
    yield call(reloadProductSaga, productSlug, OPTION_LIBRARY)
    yield put(
      showNotification({
        type: 'success',
        message: 'Successfully updated',
      }),
    )
  } catch (error) {
    yield* errorAndNotification(error as APIError, 'Something went wrong. Please try again later.')
    yield put(refetchSizes(productSlug, hierarchySlug))
    yield call(reloadProductSaga, productSlug, OPTION_LIBRARY)
  }
}

export default function* () {
  yield all([
    takeEvery(UPDATE_SIZE_ATTRIBUTION, updateSizeAttributionSaga),
    takeEvery(UPDATE_SIZES_AND_ATTRIBUTION, updateSizesAndAttributionsSaga),
  ])
}
